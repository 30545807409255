import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import projects from '../data/projects';
import MainLayout, { useMainLayoutContext } from '../layouts/MainLayout';
import DotNavigation from "../components/DotNavigation";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css"; // PhotoSwipe 기본 스타일

const SCROLL_DELAY = 500; // 원하는 딜레이 시간으로 수정 가능

export default function Works() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isTitleVisible, setIsTitleVisible] = useState(false);
    const containerRef = useRef(null);
    const isScrolling = useRef(false);
    const navigate = useNavigate();
    const [shouldShowHamburger, setShouldShowHamburger] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    
    // 터치 이벤트 관련 상태 추가
    const touchStart = useRef(null);
    const touchEnd = useRef(null);
    const minSwipeDistance = 300;

    // MainLayout에서 제공하는 햄버거 메뉴 활성화 상태 (true이면 헤더 역할 존재)
    const { isHamburgerVisible } = useMainLayoutContext();
    // 헤더 높이 (TailwindCSS "pt-32"는 8rem)
    const headerHeight = isHamburgerVisible ? "10rem" : "0px";
    
    useEffect(() => {
        const checkRatio = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setShouldShowHamburger(width <= height);
        };

        checkRatio();
        window.addEventListener('resize', checkRatio);
        return () => window.removeEventListener('resize', checkRatio);
    }, []);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize(); // 마운트 시 초기값 설정
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // 타이틀 표시 효과를 위한 useEffect
    useEffect(() => {
        if (hoveredIndex !== null) {
            // 약간의 딜레이 후 타이틀 표시
            setTimeout(() => {
                setIsTitleVisible(true);
            }, 50);
        } else {
            setIsTitleVisible(false);
        }
    }, [hoveredIndex]);

    const handleProjectClick = (projectId, e) => {
        if (!isScrolling.current) {
            navigate(`/works/project/${projectId}`);
        }
    };

    const scrollToImage = (index, smooth = true) => {
        if (isScrolling.current) return;
        
        isScrolling.current = true;
        setCurrentIndex(index);
        
        const container = containerRef.current;
        if (container) {
            container.children[index].scrollIntoView({
                behavior: smooth ? 'smooth' : 'auto',
                block: 'start'
            });
            console.log('scrollToImage', index);
            console.log('behavior: smooth', smooth);
            setTimeout(() => {
                isScrolling.current = false;
            }, smooth ? 800 : 0);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        let lastScrollTime = 0;
        const scrollThreshold = 1000;

        const handleWheel = (e) => {
            e.preventDefault();
            
            const currentTime = new Date().getTime();
            if (currentTime - lastScrollTime < scrollThreshold || isScrolling.current) {
                return;
            }

            const direction = e.deltaY > 0 ? 1 : -1;
            const nextIndex = Math.min(Math.max(currentIndex + direction, 0), projects.length - 1);
            
            if (nextIndex !== currentIndex) {
                lastScrollTime = currentTime;
                scrollToImage(nextIndex);
            }
        };

        container.addEventListener('wheel', handleWheel, { passive: false });
        
        return () => {
            container.removeEventListener('wheel', handleWheel);
        };
    }, [currentIndex, projects.length]);

    const headerOffsetClass = isHamburgerVisible ? "pt-16" : "";

    // PhotoSwipeLightbox 초기화
    const lightboxRef = useRef(null);
    useEffect(() => {
        const lightbox = new PhotoSwipeLightbox({
            gallery: "#gallery",
            children: "a",
            pswpModule: () => import("photoswipe"),
            imageClickAction: 'zoom',
            paddingFn: () => 0,
            maxZoomLevel: 4,
            showHideAnimationType: 'none',
            mouseMovePan: true,
            wheelToZoom: false,
            allowMouseDrag: true,
            allowWheelToZoom: false,
            arrowKeys: false,
            easing: 'ease-out',
            speed: 500,
            friction: 0.1,
            swipeToClose: false,
            pinchToClose: false,
            verticalDragRange: 0,
        });

        // PhotoSwipe 이벤트 리스너 추가
        lightbox.on('change', () => {
            const index = lightbox.pswp.currIndex;
            setCurrentIndex(index);
            // 해당 이미지로 스크롤
            containerRef.current?.children[index]?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        });

        lightbox.init();
        lightboxRef.current = lightbox;

        return () => lightbox.destroy();
    }, []);

    // 스크롤 이벤트 핸들러
    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const container = containerRef.current;
            const scrollTop = container.scrollTop;
            const itemHeight = container.children[0]?.offsetHeight || 0;
            
            if (itemHeight === 0) return;

            const newIndex = Math.round(scrollTop / itemHeight);
            if (newIndex !== currentIndex) {
                setCurrentIndex(newIndex);
                // PhotoSwipe 갤러리가 열려있을 때만 인덱스 동기화
                if (lightboxRef.current?.pswp?.isOpen) {
                    lightboxRef.current.pswp.goTo(newIndex);
                }
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [currentIndex]);

    return (
        <MainLayout>
            
            {/* 헤더가 활성화되었다면 고정으로 보여질 오프셋 영역 (예시로 배경색 녹색, h-32 = 8rem) */}
            {isHamburgerVisible && (
                <div className="bg-green-500 h-32 pt-32">
                    {/* 이 영역은 헤더 오프셋 영역으로, 스크롤 시에도 항상 보이게 됩니다. */}
                </div>
            )}

                      {/* Dot 인디케이터 */}
                      {!shouldShowHamburger&&    <div className="fixed right-0 top-40 z-40 flex flex-col gap-3 px-8 py-1 ">
                    {projects.map((project, index) => (
                        <div
                            key={index}
                            className={`group relative w-2 h-2 rounded-full transition-all duration-500 ease-in-out ${
                                currentIndex === index
                                    ? 'bg-white'
                                    : 'bg-gray-500 hover:bg-white'
                            }`}
                            onClick={() => scrollToImage(index)}
                            aria-label={`Go to image ${index + 1}`}
                        >
                            <span className="absolute -top-1 right-5 opacity-0 group-hover:opacity-100 transition duration-300 text-white text-sm whitespace-nowrap">
                                {project.title}
                            </span>
                        </div>
                    ))}
                </div>}
            {/* 스크롤 가능한 영역의 높이는 전체 뷰포트 높이에서 헤더 오프셋 높이를 뺀 값 */}
            <div
                className={`bg-black relative overflow-y-auto ${shouldShowHamburger ? "h-[90vh]" : "h-[100vh]"} ${headerOffsetClass}`}

                
            >
                <div 
                    ref={containerRef}
                    id="gallery" 
                    className="w-full overflow-y-auto snap-y snap-mandatory"
                    style={{ height: '100vh' }}
                >
                    {projects.map((project, index) => (
                        <div
                            key={project.id}
                            className="relative w-full snap-start"
                            style={{ height: 'auto' }}
                        >
                            <div className="block w-full">
                                <img
                                    src={project.image}
                                    alt={project.title}
                                    className="w-full block"
                                    style={{ 
                                        height: 'auto',
                                        verticalAlign: 'bottom'
                                    }}
                                    draggable="false"
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onClick={() => handleProjectClick(project.id)}
                                />
                                <div
                                    className={`absolute inset-0 z-10 flex items-end pointer-events-none transition-opacity duration-300 ${
                                        hoveredIndex === index ? "opacity-100" : "opacity-0"
                                    }`}
                                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                                >
                                    <div className="w-full p-20">
                                        <h2 className="text-white text-2xl font-light opacity-80">
                                            {project.title}
                                        </h2>
                                        <p className="text-white text-sm font-extralight opacity-80">
                                            {project.subTitle}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* Hello World 텍스트 추가 */}
                    <div className="w-full flex justify-center items-center py-8 snap-start">
                        <div className="text-white  text-md xxs:text-xs sm:text-lg md:text-xl lg:text-xl xl:text-xl font-light">@2025 BEODD</div>
                        <div className="text-white  text-md xxs:text-xs sm:text-lg md:text-xl lg:text-xl xl:text-xl font-light">ALL RIGHTS RESERVED</div>
                    </div>
                </div>
                
            </div>
        </MainLayout>
    );
}