import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import projects from '../data/projects';
import MainLayout, { useMainLayoutContext } from '../layouts/MainLayout';

// 개별 이미지 컴포넌트
// alignment prop(메타데이터의 alignment 값)에 따라 세로 이미지의 정렬을 결정합니다.
function ProjectImage({ src, alt, alignment = "left", isHamburgerVisible }) {
    const [isVertical, setIsVertical] = useState(null);

    const handleLoad = (e) => {
        const vertical = e.target.naturalHeight > e.target.naturalWidth;
        setIsVertical(vertical);
    };

    // 아직 이미지가 로드되지 않아 orientation 판단 전이면 hidden 처리
    if (isVertical === null) {
        return <img src={src} alt={alt} onLoad={handleLoad} className="hidden" />;
    }

    let wrapperClass = "";
    let imgClass = "";

    if (isVertical&&!isHamburgerVisible) {
        // 세로(포트레이트) 이미지인 경우 : data/1.js의 alignment 값("right" 또는 "left")에 따라 정렬
        wrapperClass = `w-full mb-4 ${alignment === "right" ? "text-right" : "text-left"}`;
        imgClass = "inline-block w-auto max-w-3xl object-cover";
    } else if (isHamburgerVisible) {
        // 가로(landscape) 이미지인 경우 : 별도의 정렬 적용 없이 기본 설정으로 표시
        wrapperClass = "w-full mb-4";
        imgClass = "w-auto object-cover";
    }else{
        // 가로(landscape) 이미지인 경우 : 별도의 정렬 적용 없이 기본 설정으로 표시
        wrapperClass = "w-full mb-4";
        imgClass = "w-auto object-cover";
    }

    return (
        <div className={wrapperClass}>
            <img src={src} alt={alt} onLoad={handleLoad} className={imgClass} />
        </div>
    );
}


export default function ProjectDetail() {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [shouldShowHamburger, setShouldShowHamburger] = useState(false);
    const { isHamburgerVisible } = useMainLayoutContext();
    useEffect(() => {
        const checkRatio = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setShouldShowHamburger(width <= height);
        };

        checkRatio();
        window.addEventListener('resize', checkRatio);
        return () => window.removeEventListener('resize', checkRatio);
    }, []);
    useEffect(() => {
        const projectId = parseInt(id);
        const currentProject = projects.find(p => p.id === projectId);
        if (currentProject) {
            setProject(currentProject);
            // 현재 프로젝트 인덱스 저장
            const projectIndex = projects.findIndex(p => p.id === projectId);
            localStorage.setItem('lastViewedProjectIndex', projectIndex.toString()
            );
        }
    }, [id]);

    if (!project) {
        return <div>Loading...</div>;
    }

    // 0부터 18까지 19개의 이미지를 불러옵니다.
    const images = Array.from({ length: 18 }, (_, idx) => `/works/projects/${project.id}/contents/${idx + 1}.webp`);

    // 이미지 정렬 옵션: "left" 이면 좌측 정렬, "right" 이면 우측 정렬
    const imageAlignment = "right"; // "left" 또는 "right" 로 조정 가능
    const alignmentClass = imageAlignment === "right" ? "text-right" : "text-left";

    return (
        <MainLayout>
            {/* 최상위 컨테이너에서는 min-h-screen 대신 내용에 따라 높이가 늘어나도록 함 */}
            {isHamburgerVisible && (
                <div className="fixed bg-green-500 h-32 pt-32">
                    {/* 이 영역은 헤더 오프셋 영역으로, 스크롤 시에도 항상 보이게 됩니다. */}
                </div>
            )}
              {isHamburgerVisible && (
            <div className='fixed top-0 left-0 w-full bg-black h-20 z-30'></div>
              )}
            <div className="bg-black">
                <div className={`${shouldShowHamburger ? "pt-0" : "pt-32"}`}>
                    {/* 프로젝트 정보 영역 */}
                    <div className="px-10 py-16">
                        <div className="flex flex-col gap-8 max-w-2xl">
                            {/* 타이틀 및 서브타이틀 */}
                            <div className="flex flex-col">
                                <h1 className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-light text-white">{project.title}</h1>
                                <h2 className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-light text-white opacity-60">{project.subTitle}</h2>
                            </div>

                            {/* 프로젝트 상세 정보 리스트 */}
                            <div className="flex flex-col text-white">
                                <div className="flex">
                                    <span className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl break-words">CLIENT : &nbsp;&nbsp;{project.client}</span>
                                </div>
                                <div className="flex">
                                    <span className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl break-words">USAGE : &nbsp;&nbsp;{project.type}</span>
                                </div>
                                <div className="flex">
                                    <span className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl break-words">SITE AREA : &nbsp;&nbsp;{project.siteArea}</span>
                                </div>
                                <div className="flex">
                                    <span className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl break-words">SCOPE : &nbsp;&nbsp;{project.involvement}</span>
                                </div>
                                <div className="flex">
                                    <span className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl break-words">LOCATION : &nbsp;&nbsp;{project.location}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 이미지 리스트 영역 */}
                    <div className={`${shouldShowHamburger ? "pb-10 pt-10 " : "px-10 pb-10 pt-28"}`}>
                        {project.contents.map((item, idx) => (
                            <ProjectImage
                                key={idx}
                                src={`/works/projects/${project.id}/contents/${item.id}.webp`}
                                alt={`프로젝트 이미지 ${idx}`}
                                alignment={item.alignment} // 메타데이터의 alignment 값 ("left" 또는 "right")
                                isHamburgerVisible={shouldShowHamburger}
                            />
                        ))}
                    </div>
                    
                    {/* Hello World 텍스트 추가 */}
                    <div className="w-full flex justify-center items-center pb-10 snap-start">
                        <div className="text-white text-md xxs:text-xs sm:text-lg md:text-xl lg:text-xl xl:text-xl font-light">@2025 BEODD</div>
                        <div className="text-white text-md xxs:text-xs sm:text-lg md:text-xl lg:text-xl xl:text-xl font-light">ALL RIGHTS RESERVED</div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}
