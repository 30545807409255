import { Link } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import { useState, useEffect } from 'react';
import useLockScroll from "../hooks/useLockScroll";

export default function About() {
    const [shouldShowHamburger, setShouldShowHamburger] = useState(false);  
    useEffect(() => {
        const checkRatio = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setShouldShowHamburger(width <= height);
        };

        checkRatio();
        window.addEventListener('resize', checkRatio);
        return () => window.removeEventListener('resize', checkRatio);
    }, []);

    // About 페이지가 마운트되면 스크롤이 막힙니다
    useLockScroll();

    return (
        <MainLayout>
            {/* About 페이지 컨텐츤 */}
            <div className={`  flex items-center justify-center px-14 ${shouldShowHamburger ? "h-[100vh-300px]" : "h-[100vh]"}`}>
                <img
                    src={`${shouldShowHamburger ? "/about/1.png" : "/about/0.png"}`}
                    alt="About"

                    className="object-contain "
                />

                {/* 하단 고정 텍스트 영역 */}
                <div className="fixed bottom-10 left-6 w-full bg-transparent px-10 py-10  flex justify-start items-center ">
                    <div className="text-[#e2e2e2] max-w-3xl">

                    </div>
                    <div className="flex gap-3 flex-col">
                        <Link to="/portfolio" className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative w-fit
        after:content-[''] after:absolute after:bottom-0 after:left-0 
        after:h-[1px] after:w-full after:bg-white after:opacity-0 
        after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >PORTFOLIO</Link>
                        <Link to="/introduction" className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative  w-fit
        after:content-[''] after:absolute after:bottom-0 after:left-0 
        after:h-[1px] after:w-full after:bg-white after:opacity-0 
        after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >INTRODUCTION</Link>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}