import { Link } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import { useState, useEffect } from 'react';
import useLockScroll from "../hooks/useLockScroll";

export default function Contact() {
    const [shouldShowHamburger, setShouldShowHamburger] = useState(false);  
    useEffect(() => {
        const checkRatio = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setShouldShowHamburger(width <= height);
        };

        checkRatio();
        window.addEventListener('resize', checkRatio);
        return () => window.removeEventListener('resize', checkRatio);
    }, []);
    useLockScroll();
    return (
        <MainLayout>
            {/* Contact 컨텐츠 */}
            <div className="w-full h-full">
                <div className={`flex justify-between  items-center ${shouldShowHamburger ? "h-[50vh]" : "h-[100vh]"} px-10 `}> {/* 전체 높이에서 헤더 높이 제외 */}
                    {/* 왼쪽 연락처 정보 */}
                    <div className="flex flex-col gap-6 text-[#e2e2e2] h-[60vh] justify-center">
                        <div>
                            <p className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl ">T 02-798-8744</p>
                            <p className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl ">F 02-798-8743</p>
                        </div>
                        <div>
                            <p className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">EMAIL : info@beodd.kr</p>
                        </div>
                        <div>
                            <p className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">ADD : A-B1, 39-9, HANGANG-DAERO 40-GIL, YONGSAN-GU, SEOUL, KOREA</p>
                        </div>
                        <div>
                            <p className="text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">INSTAGRAM : @BEODD_STUDIO</p>
                        </div>
                    </div>

                    {/* 오른쪽 링크 목록 */}
                   {!shouldShowHamburger && <div className="fixed bottom-0 right-10 flex flex-col gap-4 text-left text-white justify-end pb-32 ">
                        
                        <a
                            href="https://blog.naver.com/beodd_studio"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xlcursor-pointer relative w-fit
                            after:content-[''] after:absolute after:bottom-0 after:left-0 
                            after:h-[1px] after:w-full after:bg-white after:opacity-0 
                            after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >
                            BLOG
                        </a>
                        <a
                            href="https://www.instagram.com/beodd_studio/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-whitetext-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative w-fit
                            after:content-[''] after:absolute after:bottom-0 after:left-0 
                            after:h-[1px] after:w-full after:bg-white after:opacity-0 
                            after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >
                            INSTAGRAM
                        </a>
                        <a
                            href="mailto:info@beodd.kr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative w-fit
                            after:content-[''] after:absolute after:bottom-0 after:left-0 
                            after:h-[1px] after:w-full after:bg-white after:opacity-0 
                            after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >
                            CONTACT US
                        </a>
                    </div>
}
                   {/* 오른쪽 링크 목록 */}
                   {shouldShowHamburger && <div className="fixed bottom-10 left-0 flex flex-col gap-4 text-left text-white  w-full bg-transparent px-10 py-10   justify-between items-start">
                        
                        <a
                            href="https://blog.naver.com/beodd_studio"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative w-fit
                            after:content-[''] after:absolute after:bottom-0 after:left-0 
                            after:h-[1px] after:w-full after:bg-white after:opacity-0 
                            after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >
                            BLOG
                        </a>
                        <a
                            href="https://www.instagram.com/beodd_studio/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative w-fit
                            after:content-[''] after:absolute after:bottom-0 after:left-0 
                            after:h-[1px] after:w-full after:bg-white after:opacity-0 
                            after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >
                            INSTAGRAM
                        </a>
                        <a
                            href="mailto:info@beodd.kr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-md xxs:text-[1rem] xs:text-[1rem] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl cursor-pointer relative w-fit
                            after:content-[''] after:absolute after:bottom-0 after:left-0 
                            after:h-[1px] after:w-full after:bg-white after:opacity-0 
                            after:transition-opacity after:duration-500 hover:after:opacity-100"
                        >
                            CONTACT US
                        </a>
                    </div>
}
                </div>
            </div>
        </MainLayout>
    );
}