import { useState, useEffect } from 'react';

export default function ResolutionDisplay() {
    const [resolution, setResolution] = useState({ width: 0, height: 0, ratio: '' });

    const calculateAspectRatio = (width, height) => {
        const ratio = width / height;
        
        // 표준 비율 정의
        const standardRatios = [
            { ratio: 4/3, name: '4:3' },
            { ratio: 3/4, name: '3:4' },
            { ratio: 16/9, name: '16:9' },
            { ratio: 9/16, name: '9:16' },
            { ratio: 1, name: '1:1' }
        ];

        // 현재 비율과 표준 비율들 간의 차이를 계산하여 가장 가까운 것을 찾음
        let closestRatio = standardRatios[0];
        let minDifference = Math.abs(ratio - standardRatios[0].ratio);

        standardRatios.forEach(standard => {
            const difference = Math.abs(ratio - standard.ratio);
            if (difference < minDifference) {
                minDifference = difference;
                closestRatio = standard;
            }
        });

        return closestRatio.name;
    };

    useEffect(() => {
        const updateResolution = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            const ratio = calculateAspectRatio(width, height);
            setResolution({ width, height, ratio });
        };

        updateResolution();
        window.addEventListener('resize', updateResolution);
        return () => window.removeEventListener('resize', updateResolution);
    }, []);

    return (
        <div className="fixed top-0 right-0 bg-black/50 text-white p-2 text-xs z-50 flex justify-between items-center gap-2 w-screen">
              <div className='text-xs text-indigo-800'>v 0.4d</div>
            <div>{resolution.width} x {resolution.height} ({resolution.ratio})</div>
        </div>
    );
} 