import { useEffect } from 'react';

export default function useLockScroll() {
  useEffect(() => {
    // 기존의 body overflow 값을 저장합니다.
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // 스크롤을 막기 위해 overflow를 hidden으로 설정합니다.
    document.body.style.overflow = 'hidden';

    // 컴포넌트 언마운트 시 원래 값으로 복원합니다.
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
} 