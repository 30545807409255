const projects = [
    {
        id: 1,
        image: '/works/projects/1/0.webp',
        illustration:'/works/projects/object/1.webp',
        title: 'MONGTAN JEJU',
        subTitle: 'CEREMONY',
        type: 'BARBECUE RESTAURANT',
        client: 'MT FOOD',
        contents:[
            {
                id: 1,
                alignment: 'right',
            },
            {
                id: 2,
                alignment: 'left',
            },
            {
                id: 3,
                alignment: 'right',
            },
            {
                id: 4,
                alignment: 'left',
            },
            {
                id: 5,
                alignment: 'right',
            },
            {
                id: 6,
                alignment: 'left',
            },  
            {
                id: 7,
                alignment: 'right',
            },  
            {
                id: 8,
                alignment: 'left',
            },  
            {
                id: 9,
                alignment: 'right',
            },        
            {
                id: 10,
                alignment: 'left',
            },  
            {
                id: 11,
                alignment: 'right',
            },  
            {
                id: 12,
                alignment: 'left',
            },  
            {
                id: 13,
                alignment: 'right',
            },  
            {   
                id: 14,
                alignment: 'left',
            },  
            {
                id: 15,
                alignment: 'right',
            },  
            {
                id: 16,
                alignment: 'left',
            },  
            {
                id: 17,
                alignment: 'right',
            },  
            {
                id: 18,
                alignment: 'left',
            },  
            {   
                id: 19,
                alignment: 'right',
            }
            
        ],
        siteArea: '329.8M2 / 99.93PY',
        location: 'GUJWA-EUP, JEJU-SI, JEJU-DO',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION, BRANDING',
    },
  
    {
        id: 2,
        image: '/works/projects/2/0.webp',
        illustration:'/works/projects/object/2.webp',
        title: 'AOMG OFFICE',
        subTitle: 'MIND-SET',
        type: 'OFFICE',
        client: 'AOMG',
        contents:[
            {
                id: 1,
                alignment: 'right',
            },
            {
                id: 2,
                alignment: 'left',
            },
            {
                id: 3,
                alignment: 'right',
            },
            {
                id: 4,
                alignment: 'left',
            },
            {
                id: 5,
                alignment: 'right',
            },
            {
                id: 6,
                alignment: 'left',
            },  
            {
                id: 7,
                alignment: 'right',
            },  
            {
                id: 8,
                alignment: 'left',
            },  
            {
                id: 9,
                alignment: 'right',
            },        
            {
                id: 10,
                alignment: 'left',
            },  
            {
                id: 11,
                alignment: 'right',
            },  
            {
                id: 12,
                alignment: 'left',
            },              
        ],
        siteArea: '1070M2 / 323.7PY',
        location: 'SEONGDONG-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 21,
        image: '/works/projects/21/0.webp',
        illustration:'/works/projects/object/21.webp',
        title: 'REJURAN FLAGSHIP STORE',
        subTitle: 'MYSTIC OCEAN',
        type: 'OFFICE',
        client: 'REJURAN COSMETICS',
        contents:[
            {
                id: 1,
                alignment: 'right',
            },
            {
                id: 2,
                alignment: 'left',
            },
            {
                id: 3,
                alignment: 'right',
            },
            {
                id: 4,
                alignment: 'left',
            },
            {
                id: 5,
                alignment: 'right',
            },
            {
                id: 6,
                alignment: 'left',
            },  
            {
                id: 7,
                alignment: 'right',
            },  
            {
                id: 8,
                alignment: 'left',
            },  
            {
                id: 9,
                alignment: 'right',
            },        
            {
                id: 10,
                alignment: 'left',
            },  
            {
                id: 11,
                alignment: 'right',
            },  
            {
                id: 12,
                alignment: 'left',
            },              
        ],
        siteArea: '25.48M2 / 7.72PY',
        location: 'SONGPA-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 3,
        image: '/works/projects/3/0.webp',
        illustration:'/works/projects/object/3.webp',
        title: 'SHUIT W',
        subTitle: "W, W'",
        type: 'BESPOKE SHOP',
        client: 'SHUIT W',
         contents:[
            {
                id: 1,
                alignment: 'right',
            },
            {
                id: 2,
                alignment: 'left',
            },
            {
                id: 3,
                alignment: 'right',
            },
            {
                id: 4,
                alignment: 'left',
            },
            {
                id: 5,
                alignment: 'right',
            },
            {
                id: 6,
                alignment: 'left',
            },  
            {
                id: 7,
                alignment: 'right',
            },  
            {
                id: 8,
                alignment: 'left',
            },  
            {
                id: 9,
                alignment: 'right',
            },        
            {
                id: 10,
                alignment: 'left',
            },  
            {
                id: 11,
                alignment: 'right',
            },  
            {
                id: 12,
                alignment: 'left',
            },  
            {
                id: 13,
                alignment: 'right',
            },  
            {   
                id: 14,
                alignment: 'left',
            },  
            {
                id: 15,
                alignment: 'right',
            },  
            {
                id: 16,
                alignment: 'left',
            },  
            {
                id: 17,
                alignment: 'right',
            },  
            {
                id: 18,
                alignment: 'left',
            },  
            {   
                id: 19,
                alignment: 'right',
            },        
            {
                id: 20,
                alignment: 'left',
            },  
            {
                id: 21,
                alignment: 'right',
            },  
            {
                id: 22,
                alignment: 'left',
            },  
            {
                id: 23,
                alignment: 'right',
            },  
            {   
                id: 24,
                alignment: 'left',
            },  
            {
                id: 25,
                alignment: 'right',
            },  
            {
                id: 26,
                alignment: 'left',
            },  
            {
                id: 27,
                alignment: 'right',
            },  
            {
                id: 28,
                alignment: 'left',
            },  
            {   
                id: 29,
                alignment: 'right',
            },  
            {   
                id: 30,
                alignment: 'right',
            }
            
        ],
        siteArea: '322M2 / 97.57PY',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 4,
        image: '/works/projects/4/0.webp',
        illustration:'/works/projects/object/4.webp',
        title: 'MOTUNGIWOO HIGHTABLE',
        subTitle: 'SOMMELIER',
        type: 'WINE DINING BAR',
        client: 'MOTUNGIWOO',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            }
        ],
        siteArea: '322.16M2 / 97.62PY',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 5,
        image: '/works/projects/5/0.webp',
        illustration:'/works/projects/object/5.webp',
        title: 'GOONIES',
        subTitle: 'NEVER SAY NEVER!',
        type: 'CAFE',
        client: 'BEODD',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            },  
            {
                id: 15,
                image: '/works/projects/1/contents/15.webp',
                alignment: 'right',
            },  
            {
                id: 16,
                image: '/works/projects/1/contents/16.webp',
                alignment: 'left',
            },  
            {
                id: 17,
                image: '/works/projects/1/contents/17.webp',
                alignment: 'right',
            },  
            {
                id: 18,
                image: '/works/projects/1/contents/18.webp',
                alignment: 'left',
            },  
            {   
                id: 19,
                image: '/works/projects/1/contents/19.webp',
                alignment: 'right',
            }
            
        ],
        siteArea: '57.92M2 / 17.57PY',
        location: 'YONGSAN-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION, BRANDING'
    },
    {
        id: 6,
        image: '/works/projects/6/0.webp',
        illustration:'/works/projects/object/6.webp',
        title: 'GROVE',
        subTitle: 'BIOLOGY MUSEUM',
        type: 'FLOWER SHOWROOM',
        client: 'GROVE',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            }
        ],
        siteArea: '192.2M2 / 58.14PY',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONSTRUCTION'
    },
    {
        id: 7,
        image: '/works/projects/7/0.webp',
        illustration:'/works/projects/object/7.webp',
        title: 'SALTY SEOUL',
        subTitle: 'FEAST OF WAVES',
        type: 'CASUAL DINING BAR',
        client: 'SALTY SEOUL',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            }
        ],
        siteArea: '88.59M2 / 26.8PY',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION, GRAPHIC'
    },
    {
        id: 8,
        image: '/works/projects/8/0.webp',
        illustration:'/works/projects/object/8.webp',
        title: 'SHUIT W',
        subTitle: 'THE PROCESS',
        type: 'BESPOKE SHOP',
        client: 'SHUIT W',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            },  
            {
                id: 15,
                image: '/works/projects/1/contents/15.webp',
                alignment: 'right',
            },  
            {
                id: 16,
                image: '/works/projects/1/contents/16.webp',
                alignment: 'left',
            },  
            {
                id: 17,
                image: '/works/projects/1/contents/17.webp',
                alignment: 'right',
            },  
            {
                id: 18,
                image: '/works/projects/1/contents/18.webp',
                alignment: 'left',
            }
            
        ],
        siteArea: '217.49M2 / 65.79PY',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 9,
        image: '/works/projects/9/0.webp',
        illustration:'/works/projects/object/9.webp',
        title: 'N-PIXEL OFFICE',
        subTitle: "P'OSSIBILITY",
        type: 'OFFICE',
        client: 'N-PIXEL',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            },  
            {
                id: 15,
                image: '/works/projects/1/contents/15.webp',
                alignment: 'right',
            },  
            {
                id: 16,
                image: '/works/projects/1/contents/16.webp',
                alignment: 'left',
            },  
            {
                id: 17,
                image: '/works/projects/1/contents/17.webp',
                alignment: 'right',
            }
            
        ],
        siteArea: '1859.46M2 / 563.16PY (1F)',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'DIRECTION'
    },
    {
        id: 10,
        image: '/works/projects/10/0.webp',
        illustration:'/works/projects/object/10.webp',
        title: 'HYUNDAI PEER',
        subTitle: 'UNEXPECTED',
        type: 'EDIT SHOP',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            }
            
        ],
        client: 'HYUNDAI DEPARTMENT STORE',
        siteArea: '241.25M2 / 73.1PY (1ST)',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, GRAPHIC'
    },
    {
        id: 11,
        image: '/works/projects/11/0.webp',
        illustration:'/works/projects/object/11.webp',
        title: 'ALAIN DELON',
        subTitle: 'DAYDREAM',
        type: 'FRENCH RESTAURANT',
        client: 'ALAIN DELON',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            }
            
        ],
        siteArea: '53M2 / 16PY',
        location: 'YONGSAN-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION, BRANDING'
    },
    {
        id: 12,
        image: '/works/projects/12/0.webp',
        illustration:'/works/projects/object/12.webp',
        title: 'AVECQUE',
        subTitle: 'BLENDING',
        type: 'RESTAURANT',
        client: 'AVECQUE',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            },  
            {
                id: 15,
                image: '/works/projects/1/contents/15.webp',
                alignment: 'right',
            }
            
        ],
        siteArea: '343.2M2 / 104PY (1ST)',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION, GRAPHIC'
    },
    {
        id: 13,
        image: '/works/projects/13/0.webp',
        illustration:'/works/projects/object/13.webp',
        title: 'ADER SPACE 1.0',
        subTitle: 'BUT NEAR MISSED WAY',
        type: 'FLAGSHIP STORE',
        client: 'ADERERROR',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            },  
            {
                id: 15,
                image: '/works/projects/1/contents/15.webp',
                alignment: 'right',
            },  
            {
                id: 16,
                image: '/works/projects/1/contents/16.webp',
                alignment: 'left',
            }
            
        ],
        location: 'MAPO-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 14,
        image: '/works/projects/14/0.webp',
        illustration:'',
        title: 'GENTLEMONSTER',
        subTitle: 'LAST PIECE',
        type: 'FLAGSHIP STORE',
        client: 'GENTLEMONSTER',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            }
            
        ],
        location: 'NINGBO, CHINA',
        involvement: 'FURNITURE DESIGN'
    },
    {
        id: 15,
        image: '/works/projects/15/0.webp',
        illustration:'/works/projects/object/15.webp',
        title: 'JUUN.J',
        subTitle: 'DIVERSION OF IMAGINE',
        type: 'FLAGSHIP STORE',
        client: 'JUUN.J',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            }
            
        ],
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'LIGHTING TEXTURE DESIGN'
    },
    {
        id: 16,
        image: '/works/projects/16/0.webp',
        illustration:'/works/projects/object/16.webp',
        title: 'LIGHT HOUSE',
        subTitle: 'NOSTALGIA',
        type: 'CAFE',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            },  
            {
                id: 13,
                image: '/works/projects/1/contents/13.webp',
                alignment: 'right',
            },  
            {   
                id: 14,
                image: '/works/projects/1/contents/14.webp',
                alignment: 'left',
            }
            
        ],
        client: 'ILKWANG LIGHTING',
        siteArea: '385.95M2 / 116.95PY',
        location: 'JUNG-GU, INCHEON',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 17,
        image: '/works/projects/17/0.webp',
        illustration:'/works/projects/object/17.webp',
        title: 'CHAPTER1 COLLECT',
        subTitle: 'WATCH FOR SPACE',
        type: 'LIFE LIVING SHOP',
        client: 'PPS CORP.',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            },  
            {
                id: 11,
                image: '/works/projects/1/contents/11.webp',
                alignment: 'right',
            },  
            {
                id: 12,
                image: '/works/projects/1/contents/12.webp',
                alignment: 'left',
            }
            
        ],
        location: 'SEONGBUK-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 18,
        image: '/works/projects/18/0.webp',
        illustration:'/works/projects/object/18.webp',
        title: 'ENDIVE',
        subTitle: '1, 0, ㅇ, ㅣ',
        type: 'CAFE / FLAGSHIP STORE',
        client: 'LYNN COMPANY',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            }
            
        ],
        location: 'YONGSAN-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 19,
        image: '/works/projects/19/0.webp',
        illustration:'/works/projects/object/19.webp',
        title: 'BOSKETT',
        subTitle: 'THE LAB',
        type: 'BRUNCH RESTAURANT',
        client: 'DANK DESIGN HAUS',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            }
            
        ],
        siteArea: '109.57M2 / 33.14PY',
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    },
    {
        id: 20,
        image: '/works/projects/20/0.webp',
        illustration:'/works/projects/object/20.webp',
        title: 'CHAPTER1 SELECT',
        subTitle: 'UNFAMILIAR BEAUTY',
        type: 'LIFE LIVING SHOP',
        client: 'PPS CORP.',
        contents:[
            {
                id: 1,
                image: '/works/projects/1/contents/1.webp',
                alignment: 'right',
            },
            {
                id: 2,
                image: '/works/projects/1/contents/2.webp',
                alignment: 'left',
            },
            {
                id: 3,
                image: '/works/projects/1/contents/3.webp',
                alignment: 'right',
            },
            {
                id: 4,
                image: '/works/projects/1/contents/4.webp',
                alignment: 'left',
            },
            {
                id: 5,
                image: '/works/projects/1/contents/5.webp',
                alignment: 'right',
            },
            {
                id: 6,
                image: '/works/projects/1/contents/6.webp',
                alignment: 'left',
            },  
            {
                id: 7,
                image: '/works/projects/1/contents/7.webp',
                alignment: 'right',
            },  
            {
                id: 8,
                image: '/works/projects/1/contents/8.webp',
                alignment: 'left',
            },  
            {
                id: 9,
                image: '/works/projects/1/contents/9.webp',
                alignment: 'right',
            },        
            {
                id: 10,
                image: '/works/projects/1/contents/10.webp',
                alignment: 'left',
            }
            
        ],
        location: 'GANGNAM-GU, SEOUL',
        involvement: 'CONCEPT DESIGN, CONSTRUCTION'
    }
];

export default projects; 