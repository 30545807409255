import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import ResolutionDisplay from '../components/ResolutionDisplay';
import Lottie from 'lottie-react';
import hamburgerAnimation from '../assets/hamburger.json';

// Context 생성 (기본값)
const MainLayoutContext = createContext({
  isHamburgerVisible: false,
  setHamburgerVisible: () => {}
});

// useMainLayoutContext 훅을 named export
export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default function MainLayout({ children }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [shouldShowHamburger, setShouldShowHamburger] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);
    const location = useLocation();
    const [lottieDirection, setLottieDirection] = useState(1);
    const lottieRef = useRef();

    useEffect(() => {
        const checkRatio = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setShouldShowHamburger(width <= height);
        };

        checkRatio();
        window.addEventListener('resize', checkRatio);
        return () => window.removeEventListener('resize', checkRatio);
    }, []);

    // 페이지 전환 시 메뉴 상태 처리
    useEffect(() => {
        if (isMenuOpen) {
            setIsNavigating(true);
            // 페이지 전환 후 메뉴 닫기
            const timer = setTimeout(() => {
                setIsMenuOpen(false);
                setIsNavigating(false);
            }, 300); // 애니메이션 시간과 동일하게 설정
            return () => clearTimeout(timer);
        }
    }, [location]);

    useEffect(() => {
        if (lottieRef.current) {
            // 애니메이션 속도를 3배로 설정
            lottieRef.current.setSpeed(2);
            
            if (isMenuOpen) {
                // 애니메이션의 전반부만 실행 (0에서 50%까지)
                lottieRef.current.setDirection(1);
                lottieRef.current.playSegments([0, 40], true);
            } else {
                // 애니메이션의 후반부만 실행 (50%에서 0까지 역방향)
                lottieRef.current.setDirection(-1);
                lottieRef.current.playSegments([40, 0], true);
            }
        }
    }, [isMenuOpen]);

    // children을 복제하면서 isMenuOpen prop 전달
    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { isMenuOpen });
        }
        return child;
    });

    return (
        <MainLayoutContext.Provider value={{ isHamburgerVisible: shouldShowHamburger, setHamburgerVisible: setShouldShowHamburger }}>
            <div className=" w-screen h-screen ">
                <ResolutionDisplay />
                {/* 헤더 - 항상 최상단에 고정 */}
                <div className={`fixed top-0 left-0 w-full h-24 z-40 ${shouldShowHamburger?"bg-black":"bg-transparent"}    flex items-center justify-between px-10`}>
                    <Link to="/" className="cursor-pointer">
                        <img src="/home/logo.png" alt="logo" className="xxxs:w-[50px] xxs:w-[50px] xs:w-[50px] w-[70px]" />
                    </Link>
                    
                    {!shouldShowHamburger && (
              <div className="flex justify-center items-center gap-20">
                <Link
                  to="/works"
                  className="text-white text-md cursor-pointer relative w-fit
                    after:content-[''] after:absolute after:bottom-0 after:left-0 
                    after:h-[1px] after:w-full after:bg-white after:opacity-0 
                    after:transition-opacity after:duration-500 hover:after:opacity-100"
                >
                  WORKS
                </Link>
                <Link
                  to="/about"
                  className="text-white text-md cursor-pointer relative w-fit
                    after:content-[''] after:absolute after:bottom-0 after:left-0 
                    after:h-[1px] after:w-full after:bg-white after:opacity-0 
                    after:transition-opacity after:duration-500 hover:after:opacity-100"
                >
                  ABOUT
                </Link>
                <Link
                  to="/contact"
                  className="text-white text-md cursor-pointer relative w-fit
                    after:content-[''] after:absolute after:bottom-0 after:left-0 
                    after:h-[1px] after:w-full after:bg-white after:opacity-0 
                    after:transition-opacity after:duration-500 hover:after:opacity-100"
                >
                  CONTACT
                </Link>
              </div>
            )}

                    {shouldShowHamburger && (
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <Lottie 
                                lottieRef={lottieRef}
                                animationData={hamburgerAnimation}
                                className="w-10 h-10"
                                loop={false}
                                autoplay={false}
                                onComplete={() => {
                                    if (lottieRef.current) {
                                        lottieRef.current.pause();
                                    }
                                }}
                                rendererSettings={{
                                    preserveAspectRatio: 'xMidYMid slice'
                                }}
                            />
                        </button>
                    )}
                </div>

                {/* 메뉴 오버레이에 애니메이션 추가 */}
              {shouldShowHamburger && <div 
                    className={`fixed top-20 left-0 h-[calc(100vh)] bg-black w-full z-50 transition-opacity duration-300 ${
                        isMenuOpen || isNavigating ? 'opacity-100' : 'opacity-0 pointer-events-none'
                    }`}
                >
                    <div className="flex flex-col justify-center  h-[50%]  pl-10 gap-6">
                        <Link 
                            to="/works" 
                            className="text-white text-xl cursor-pointer"
                        >
                            WORKS
                        </Link>
                        <Link 
                            to="/about" 
                            className="text-white text-xl cursor-pointer"
                        >
                            ABOUT
                        </Link>
                        <Link 
                            to="/contact" 
                            className="text-white text-xl cursor-pointer"
                        >
                            CONTACT
                        </Link>
                    </div>
                </div>} 

                {/* 메인 컨텐츠에 isMenuOpen 상태 전달 */}
                <main className={`${shouldShowHamburger ? "pt-20 bg-black" : "pt-0 bg-transparent"} `}>
                    {childrenWithProps}
                </main>
            </div>
        </MainLayoutContext.Provider>
    );
} 